import * as React from 'react';
import {FC, useState} from 'react';
import missions from '../db/missions.json';
import extra from './../db/extra.json';
import {useCodeMaxStore} from '../state/store';
import {getStatus} from '../utils/common';

import 'react-toastify/dist/ReactToastify.css';
import {MissionBlock} from "./MissionBlock";


interface SkillData {
  id: number;
  title: string;
  skill: string;
  desc: string;
  full_desc: string;
}

const useSkill = (currentStatus: string): [() => void, boolean, SkillData | undefined] => {
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const skillIndex = extra.findIndex(s => s.title === currentStatus);
  //const skill = extra[skillIndex];
  const nextSkill = skillIndex >= 0 && extra[skillIndex + 1] ? extra[skillIndex + 1] : undefined;

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  return [toggleDescription, showDescription, nextSkill];
};

export const Skills: FC = () => {
  const totalPoints = useCodeMaxStore((state) => state.points);

  const { title, color } = getStatus(totalPoints);
  const [toggleDescription, showDescription, nextSkill] = useSkill(title);

  return (
    <>
      <div className="reputationBottom">
        <MissionBlock title="Missions" missionsData={missions} />
        <div className="mission-right">
          {/*<div>*/}
          {/*  <div className="rep-board">SKILLS</div>*/}
          {/*  <div className="background-card">*/}
          {/*    {nextSkill && (*/}
          {/*      <div>*/}
          {/*        <div className="skills-list skill-shadow mission">SKILLS:*/}
          {/*          <span className="purple-shadow" style={{color}}>{nextSkill?.skill}</span>*/}
          {/*          <button className="skill-btn mission-done-btn" onClick={toggleDescription}>SHOW</button>*/}
          {/*        </div>*/}
          {/*        {showDescription && <p className="skill-desc" style={{color}}>{nextSkill?.full_desc}</p>}*/}
          {/*      </div>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</div>*/}

        </div>
      </div>
    </>
  );
};
