import {StateCreator} from 'zustand';
import {immer} from 'zustand/middleware/immer';

export interface MissionsState {
  missions: Array<boolean>;
  lastAccomplishedDate: Date;
  missionAccomplish: (index: number) => void;
  resetAllMissions: () => void;
}

export type SetFunction = (f: (state: MissionsState) => void) => void;

const ancientDate = new Date(2000, 0, 1);


export const createMissionSlice: StateCreator<MissionsState, [], [['zustand/immer', never], ...[]]> = immer((set) => ({
  missions: [true, true, true, true, true, true, true],
  lastAccomplishedDate: ancientDate,
  missionAccomplish: (index) =>
    set((state) => {
      if (index === 7) {
        return;
      }
      const currentDate = new Date();

      state.missions[index] = false;
      state.lastAccomplishedDate = currentDate;
    }),
  resetAllMissions: () =>
    set(state => {
      state.missions = [true, true, true, true, true, true, true];
    }),
}));
