import React from 'react';
import { useCodeMaxStore } from '../state/store';
import avatar from '../assets/img/avatar.jpg'
import {getStatus} from "../utils/common";

export const HunterInfo = () => {
  const totalPoints = useCodeMaxStore((state) => state.points);
  const userName = useCodeMaxStore((state) => state.user);
  const { title, color } = getStatus(totalPoints);
  const avatarStyle = {
    border: `2px solid ${color}`
  };
  const labelStyle = {
    color: `${color}`
  };

  return (
    <>
      <div className="hunter-bio">
        <img style={avatarStyle} className="hunter-photo" src={avatar} alt="Hunter avatar" />
        <div className="total-points bio-label">
          <span style={labelStyle} className="purple-shadow">{title}</span>
        </div>
        <div className="hunter-nickname bio-label">
          HUNTER: <span className="orange">{userName}</span>
        </div>
        <div className="total-points bio-label">
          REPUTATION: <span className="orange">{totalPoints}</span>
        </div>
      </div>
    </>
  );
};
