import React, { useState } from 'react';
import OpenAI from 'openai';

export const ChatBlock = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('What do you want to know?');
  const [loading, setLoading] = useState(false);

  const apiKey = process.env.REACT_APP_OPENAI_API_KEY;

  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true,
  });

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestion(event.target.value);
  };

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && question.trim() !== '') {
      setLoading(true);
      try {
        const response = await openai.chat.completions.create({
          model: "gpt-3.5-turbo",
          messages: [
            { role: "user", content: question }
          ],
          temperature: 1,
          max_tokens: 256,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0,
        });
        setAnswer(response.choices[0].message.content ?? 'No response received');
      } catch (error) {
        console.error('Error calling OpenAI API:', error);
        setAnswer('Sorry, there was an error processing your request.');
      } finally {
        setLoading(false);
        setQuestion(''); // Очистка поля ввода после отправки запроса
      }
    }
  };

  return (
    <>
      <div className="hunter-chat">
        <div className="rep-board">Chat</div>
        <div className="background-card">
          <div className="answer-block">
            <span className="answer-text">{loading ? 'Loading...' : answer}</span>
          </div>
          <input
            className="question"
            value={question}
            onChange={handleQuestionChange}
            onKeyDown={handleKeyPress}
            placeholder="Type your question..."
          />
        </div>
      </div>
    </>
  );
};
