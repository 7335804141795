import '../assets/css/main.css';
import logo from '../assets/img/kermit_palpatine_right.png';
import windowsBackground from '../assets/img/windows-gif.gif';
import {useState} from "react";
import {Hunter} from '../Hunter/Main';

export default function App() {
  // Init Hunter Screen
  const [hunterMode, setHunterMode] = useState(false);
  const [input, setInput] = useState("");

  const generateResponse = async (e?: React.KeyboardEvent<HTMLInputElement>) => {

    if (input === '/hunter') {
      setHunterMode(true);
      return;
    }
  }

  return (<>
    {hunterMode ? (<Hunter/>) : (
      <div className="app">
        <header className="app-header">
          <div className="window-wrapper">
            <div className="window-header">
              <div className="main-title">
                <img className="window-header-logo" src={logo} alt="logo"/>
                Code-Max.Tech
              </div>
            </div>
            <div className="window-body">
              <div className="flex">
                <div className="window-sidebar">
                </div>
                <div className="window-right">
                  <img className="windows-background" src={windowsBackground} alt="windows background"/>
                </div>
              </div>
              <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={(e) => generateResponse(e)}
                placeholder={"Send a message..."}>
              </input>
            </div>
          </div>
        </header>
      </div>
    )} </>);
}

