import {create} from "zustand";
import {devtools, persist} from "zustand/middleware";
import {createPointsSlice, PointsState} from './pointsSlice';
import {createCardsSlice, CardsState} from './cardsSlice';
import {createMissionSlice, MissionsState} from "./missionsSlice";

export const useCodeMaxStore = create<PointsState & CardsState & MissionsState>()(
  devtools(
    persist(
      (...a) => ({
        ...createPointsSlice(...a),
        ...createCardsSlice(...a),
        ...createMissionSlice(...a),
      }),
      {
        name: 'code-max-data',
      },
    ),
  ),
);
