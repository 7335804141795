import React from 'react';
import { Cards } from './Components/Cards';
import logo from './assets/img/logo.png';
import icon from './assets/img/icon.png';
import {ReputationBlock} from "./Components/ReputationBlock";
import './assets/css/style.css';
import './assets/css/media.css';

export const Hunter = () => {
  return (
    <div className="hunter-app">
      <div className="hunter-header-content">
        <div className="header">
          <div className="logo-placeholder">
            <a className="logo" href="/">
              <img src={logo} alt="Hunter Logo" />
            </a>
          </div>
          <div className="header-right">
            <div className="project-name">HUNTER REPUTATION BOARD</div>
          </div>
        </div>
        <div className="circle-border"></div>
        <div className="circle">
          <img className="icon-logo" src={icon} alt="hunter icon" />
        </div>
      </div>
      <div className="main">
        <Cards />
        <ReputationBlock />
      </div>
    </div>
  );
};
